import React, { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
function DataStreamList({ organizationId, onEdit  }) {
  const [dataStreams, setDataStreams] = useState([]);


  useEffect(() => {
    if (organizationId) {
      fetch(`${API_URL}/api/organizations/${organizationId}/data-streams`, { credentials: 'include' })
        .then((response) => response.json())
        .then((data) => {
          setDataStreams(data.data_streams);
        })
        .catch((error) => console.error('Fehler beim Laden der Datenströme:', error));
    }
  }, [organizationId]);

  const handleEdit = (dataStreamId) => {
    if (onEdit) {
      onEdit(dataStreamId);
    }
  };

  const handleDelete = (dataStreamId) => {
    fetch(`${API_URL}/api/data-streams/${dataStreamId}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          setDataStreams(dataStreams.filter((ds) => ds.id !== dataStreamId));
        } else {
          return response.json().then((result) => {
            console.error('Fehler beim Löschen:', result);
          });
        }
      })
      .catch((error) => console.error('Netzwerkfehler:', error));
  };

  const handleDownload = (dataStreamId) => {
    window.location.href = `${API_URL}/data-streams/${dataStreamId}/download`;
  };

  return (
    <div>
      <h3 className="text-lg font-bold mb-2">Datenströme</h3>
      {dataStreams.length > 0 ? (
        <ul>
          {dataStreams.map((ds) => (
            <li key={ds.id} className="mb-2">
              <div className="flex justify-between items-center">
                <span>{ds.name}</span>
                <div>
                  <button
                    onClick={() => handleDownload(ds.id)}
                    className="bg-blue-500 text-white p-1 rounded mr-2"
                  >
                    Herunterladen
                  </button>
                  <button
                    onClick={() => handleEdit(ds.id)}
                    className="bg-green-500 text-white p-1 rounded mr-2"
                  >
                    Bearbeiten
                  </button>
                  <button
                    onClick={() => handleDelete(ds.id)}
                    className="bg-red-500 text-white p-1 rounded"
                  >
                    Löschen
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <span>Externer Download-Link:</span>
                <a
                  href={`${API_URL}/download/${ds.download_hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline ml-2"
                >
                  Link öffnen
                </a>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>Keine Datenströme gefunden.</p>
      )}
    </div>
  );
}


export default DataStreamList;
