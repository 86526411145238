// src/pages/Pipeline.js

import React, { useState, useEffect } from 'react';
import DataInput from '../components/DataInput';
import DataStreamList from '../components/DataStreamList';
import DataStreamConfigurator from '../components/DataStreamConfigurator';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
function Pipeline() {
  const [showNewDataStream, setShowNewDataStream] = useState(false);
  const [showExistingDataStreams, setShowExistingDataStreams] = useState(false);
  const [showConfigurator, setShowConfigurator] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [fields, setFields] = useState(null);
  const [dataStreamId, setDataStreamId] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/api/organizations`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        setOrganizations(data.organizations);
        if (data.organizations.length > 0) {
          const defaultOrg = data.organizations[0];
          setSelectedOrgId(defaultOrg.id);
        }
      })
      .catch((error) => console.error('Fehler beim Laden der Organisationen:', error));
  }, []);

  const handleOrganizationChange = (e) => {
    setSelectedOrgId(e.target.value);
  };

  const toggleNewDataStream = () => {
    setShowNewDataStream(!showNewDataStream);
    setShowExistingDataStreams(false);
    setShowConfigurator(false);
  };

  const toggleExistingDataStreams = () => {
    setShowExistingDataStreams(!showExistingDataStreams);
    setShowNewDataStream(false);
    setShowConfigurator(false);
  };

  // Funktion, die aufgerufen wird, wenn das Mapping gespeichert wurde
  const handleMappingSaved = () => {
    setShowConfigurator(false);
    setShowExistingDataStreams(true);
  };

  // Funktion, die aufgerufen wird, wenn DataInput abgeschlossen ist
  const handleDataInputComplete = (fields, dataStreamId) => {
    setFields(fields);
    setDataStreamId(dataStreamId);
    setShowConfigurator(true);
    setShowNewDataStream(false);
    setShowExistingDataStreams(false);
  };

  // Funktion, um einen Datenstrom zum Bearbeiten auszuwählen
  const handleEditDataStream = (dataStreamId) => {
    setDataStreamId(dataStreamId);
    setFields(null); // Felder werden vom Backend geladen
    setShowConfigurator(true);
    setShowNewDataStream(false);
    setShowExistingDataStreams(false);
  };

  return (
    <div className="p-4">
      {showConfigurator ? (
        <DataStreamConfigurator
          fields={fields}
          dataStreamId={dataStreamId}
          onMappingSaved={handleMappingSaved}
        />
      ) : (
        <>
          <h1 className="text-2xl font-bold mb-6">Pipeline</h1>

          {/* Auswahloptionen */}
          <div className="mb-4">
            <button
              onClick={toggleNewDataStream}
              className="bg-blue-500 text-white p-2 rounded mr-4"
            >
              Neuen Datenstrom anlegen
            </button>
            <button
              onClick={toggleExistingDataStreams}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Bestehenden Datenstrom bearbeiten
            </button>
          </div>

          {/* Akkordeon für neuen Datenstrom */}
          {showNewDataStream && (
            <div className="mt-4 border-t pt-4">
              <DataInput onComplete={handleDataInputComplete} />
            </div>
          )}

          {/* Akkordeon für bestehende Datenströme */}
          {showExistingDataStreams && (
            <div className="mt-4 border-t pt-4">
              {/* Organisationsauswahl */}
              <div className="mb-4">
                <label htmlFor="organization" className="block mb-2">Organisation auswählen:</label>
                <select
                  id="organization"
                  value={selectedOrgId}
                  onChange={handleOrganizationChange}
                  className="border p-2 w-full"
                  required
                >
                  <option value="">Bitte wählen...</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </div>
              <DataStreamList
                organizationId={selectedOrgId}
                onEdit={handleEditDataStream}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Pipeline;
