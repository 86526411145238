import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function DataInput({ onComplete }) {
  const [inputType, setInputType] = useState('url'); // 'url', 'ftp', 'upload'
  const [url, setUrl] = useState('');
  const [authType, setAuthType] = useState('none'); // 'none', 'basic', 'api_key'
  const [basicAuth, setBasicAuth] = useState({ username: '', password: '' });
  const [apiKey, setApiKey] = useState('');
  const [ftpCredentials, setFtpCredentials] = useState({ host: '', username: '', password: '' });
  const [selectedFile, setSelectedFile] = useState(null);

  // Organisationen und Datenströme
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [dataStreams, setDataStreams] = useState([]);
  const [selectedDataStreamId, setSelectedDataStreamId] = useState('');
  const [dataStreamName, setDataStreamName] = useState('');
  const navigate = useNavigate();

  // Organisationen laden
  useEffect(() => {
    fetch(`${API_URL}/api/organizations`, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        setOrganizations(data.organizations);
        if (data.organizations.length > 0) {
          const defaultOrg = data.organizations[0];
          setSelectedOrgId(defaultOrg.id);
        }
      })
      .catch((error) => console.error('Fehler beim Laden der Organisationen:', error));
  }, []);

  // Datenströme laden, wenn eine Organisation ausgewählt wird
  useEffect(() => {
    if (selectedOrgId) {
      fetch(`${API_URL}/api/organizations/${selectedOrgId}/data-streams`, { credentials: 'include' })
        .then((response) => response.json())
        .then((data) => {
          setDataStreams(data.data_streams);
        })
        .catch((error) => console.error('Fehler beim Laden der Datenströme:', error));
    }
  }, [selectedOrgId]);

  // Handler für Organisationsänderung
  const handleOrganizationChange = (e) => {
    setSelectedOrgId(e.target.value);
    setSelectedDataStreamId(''); // Datenstrom-Auswahl zurücksetzen
  };

  // Handler für Datenstromänderung
  const handleDataStreamChange = (e) => {
    setSelectedDataStreamId(e.target.value);
    if (e.target.value) {
      // Wenn ein vorhandener Datenstrom ausgewählt wurde, kannst du hier zusätzliche Aktionen ausführen
      setDataStreamName('');
    }
  };

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
  };

  const handleAuthTypeChange = (e) => {
    setAuthType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('input_type', inputType);
    formData.append('organization_id', selectedOrgId);

    if (selectedDataStreamId) {
      formData.append('data_stream_id', selectedDataStreamId);
    } else {
      formData.append('data_stream_name', dataStreamName);
    }

    if (inputType === 'url') {
      formData.append('url', url);
      formData.append('auth_type', authType);

      if (authType === 'basic') {
        formData.append('username', basicAuth.username);
        formData.append('password', basicAuth.password);
      } else if (authType === 'api_key') {
        formData.append('api_key', apiKey);
      }
    } else if (inputType === 'ftp') {
      formData.append('host', ftpCredentials.host);
      formData.append('username', ftpCredentials.username);
      formData.append('password', ftpCredentials.password);
    } else if (inputType === 'upload') {
      formData.append('file', selectedFile);
    }

    try {
      const response = await fetch(`${API_URL}/process-data`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Erfolg:', result);
        console.log('Fields:', result.fields);
        console.log('DataStreamId:', result.data_stream_id);
        // Weiterleitung oder weitere Aktionen
        // Prüfe das Format von result.fields
        let fields = result.fields;
        if (fields && fields.item) {
          fields = fields.item; // oder passe dies entsprechend an
        }
      } if (onComplete) {
      // Verwende das Callback, um die Daten an die Pipeline-Komponente zu übergeben
      onComplete(result.fields, result.data_stream_id);
    } else {
      // Fallback-Navigation (stelle sicher, dass die Route korrekt ist)
      navigate('/pipeline', { state: { fields: result.fields, dataStreamId: result.data_stream_id } });

      }
    } catch (error) {
      console.error('Netzwerkfehler:', error);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Datenquelle hinzufügen</h2>
      <form onSubmit={handleSubmit}>
        {/* Organisationsauswahl */}
        <div className="mb-4">
          <label htmlFor="organization" className="block mb-2">Organisation auswählen:</label>
          <select
            id="organization"
            value={selectedOrgId}
            onChange={handleOrganizationChange}
            className="border p-2 w-full"
            required
          >
            <option value="">Bitte wählen...</option>
            {organizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>

        {/* Datenstromauswahl */}
        <div className="mb-4">
          <label htmlFor="dataStream" className="block mb-2">Datenstrom auswählen oder neuen Namen eingeben:</label>
          <select
            id="dataStream"
            value={selectedDataStreamId}
            onChange={handleDataStreamChange}
            className="border p-2 w-full"
          >
            <option value="">Neuen Datenstrom erstellen</option>
            {dataStreams.map((ds) => (
              <option key={ds.id} value={ds.id}>
                {ds.name}
              </option>
            ))}
          </select>
          {/* Nur anzeigen, wenn kein bestehender Datenstrom ausgewählt ist */}
          {!selectedDataStreamId && (
            <div className="mt-2">
              <label className="block mb-2">Name für neuen Datenstrom:</label>
              <input
                type="text"
                value={dataStreamName}
                onChange={(e) => setDataStreamName(e.target.value)}
                className="border p-2 w-full"
                required
              />
            </div>
          )}
        </div>

        {/* Auswahl des Eingabetyps */}
        <div className="mb-4">
          <label className="mr-4">
            <input
              type="radio"
              value="url"
              checked={inputType === 'url'}
              onChange={handleInputTypeChange}
            />
            URL
          </label>
          <label className="mr-4">
            <input
              type="radio"
              value="ftp"
              checked={inputType === 'ftp'}
              onChange={handleInputTypeChange}
            />
            FTP
          </label>
          <label>
            <input
              type="radio"
              value="upload"
              checked={inputType === 'upload'}
              onChange={handleInputTypeChange}
            />
            Datei-Upload
          </label>
        </div>

        {/* Eingabefelder für URL */}
        {inputType === 'url' && (
          <div className="mb-4">
            <label className="block mb-2">URL:</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="border p-2 w-full"
              required
            />

            {/* Auswahl der Authentifizierung */}
            <div className="mt-4">
              <label className="block mb-2">Authentifizierung:</label>
              <select
                value={authType}
                onChange={handleAuthTypeChange}
                className="border p-2 w-full"
              >
                <option value="none">Keine</option>
                <option value="basic">Basic Authentication</option>
                <option value="api_key">API-Schlüssel</option>
              </select>
            </div>

            {/* Felder für Basic Authentication */}
            {authType === 'basic' && (
              <div className="mt-4">
                <label className="block mb-2">Benutzername:</label>
                <input
                  type="text"
                  value={basicAuth.username}
                  onChange={(e) => setBasicAuth({ ...basicAuth, username: e.target.value })}
                  className="border p-2 w-full"
                  required
                />
                <label className="block mb-2 mt-2">Passwort:</label>
                <input
                  type="password"
                  value={basicAuth.password}
                  onChange={(e) => setBasicAuth({ ...basicAuth, password: e.target.value })}
                  className="border p-2 w-full"
                  required
                />
              </div>
            )}

            {/* Feld für API-Schlüssel */}
            {authType === 'api_key' && (
              <div className="mt-4">
                <label className="block mb-2">API-Schlüssel:</label>
                <input
                  type="text"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  className="border p-2 w-full"
                  required
                />
              </div>
            )}
          </div>
        )}

        {/* Eingabefelder für FTP */}
        {inputType === 'ftp' && (
          <div className="mb-4">
            <label className="block mb-2">FTP-Host:</label>
            <input
              type="text"
              value={ftpCredentials.host}
              onChange={(e) => setFtpCredentials({ ...ftpCredentials, host: e.target.value })}
              className="border p-2 w-full"
              required
            />
            <label className="block mb-2 mt-2">Benutzername:</label>
            <input
              type="text"
              value={ftpCredentials.username}
              onChange={(e) => setFtpCredentials({ ...ftpCredentials, username: e.target.value })}
              className="border p-2 w-full"
              required
            />
            <label className="block mb-2 mt-2">Passwort:</label>
            <input
              type="password"
              value={ftpCredentials.password}
              onChange={(e) => setFtpCredentials({ ...ftpCredentials, password: e.target.value })}
              className="border p-2 w-full"
              required
            />
          </div>
        )}

        {/* Eingabefeld für Datei-Upload */}
        {inputType === 'upload' && (
          <div className="mb-4">
            <label className="block mb-2">Datei hochladen:</label>
            <input
              type="file"
              accept=".csv,.xlsx,.xls,.txt,.xml,.pdf"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className="border p-2 w-full"
              required
            />
          </div>
        )}

        <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          Weiter
        </button>
      </form>
    </div>
  );
}

export default DataInput;
