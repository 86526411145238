import React from 'react';
import { Outlet } from 'react-router-dom';
import FrontendHeader from '../components/FrontendHeader';

const FrontendLayout = () => {
  return (
    <>
      <FrontendHeader />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default FrontendLayout;
