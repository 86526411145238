import React, { useState, useEffect, useMemo } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import axios from 'axios';
import EditProductForm from '../components/EditProductForm';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ProductsTable = () => {
  // Zustände für Organisationen und Produkte
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [shopUrl, setShopUrl] = useState('');
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Zustände für Sortierung
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  // Zustände für Filter
  const [filters, setFilters] = useState({
    priceOperator: '',
    priceValue1: '',
    priceValue2: '',
    purchasePriceOperator: '',
    purchasePriceValue1: '',
    purchasePriceValue2: '',
    stockOperator: '',
    stockValue: '',
    active: '',
  });

  // Zustände für Bearbeitung
  const [editingProductId, setEditingProductId] = useState(null);
  const [editedProduct, setEditedProduct] = useState({});

  // Zustände für gespeicherte Filter
  const [savedFilters, setSavedFilters] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');

  // Organisationen laden
  useEffect(() => {
    axios
      .get(`${API_URL}/api/settings`, {
        withCredentials: true,
      })
      .then((response) => {
        setOrganizations(response.data.organizations);
        if (response.data.organizations.length > 0) {
          const defaultOrg = response.data.organizations[0];
          setSelectedOrgId(defaultOrg.id);
          setShopUrl(defaultOrg.shop_url);
        }
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  // Produkte laden, wenn sich die Shop-URL ändert
  useEffect(() => {
    if (shopUrl) {
      fetchProducts(shopUrl);
    }
  }, [shopUrl]);

  const fetchProducts = (shopUrl) => {
    setLoading(true);
    axios
      .get('https://automate.vsrv.bcdevelopment.io/webhook/api/products', {
        params: { baseurl: shopUrl },
      })
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setError('Fehler beim Laden der Produkte.');
        setLoading(false);
      });
  };

  // Zeile ein- oder ausklappen
  const toggleRow = (id) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(id) ? prevRows.filter((rowId) => rowId !== id) : [...prevRows, id]
    );
  };

  // Organisation ändern
  const handleOrganizationChange = (e) => {
    const selectedOrgId = parseInt(e.target.value, 10);
    const selectedOrg = organizations.find((org) => org.id === selectedOrgId);
    if (selectedOrg) {
      setSelectedOrgId(selectedOrg.id);
      setShopUrl(selectedOrg.shop_url);
    }
  };

  // Suchanfrage ändern
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Zurücksetzen auf erste Seite bei neuer Suche
  };

  // Filter ändern
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1); // Zurücksetzen auf erste Seite bei Filteränderung
  };

  // Sortierfunktion
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Gespeicherte Filter abrufen
  useEffect(() => {
    const fetchSavedFilters = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/filters`, {
          withCredentials: true,
        });
        setSavedFilters(response.data.filters);
      } catch (err) {
        console.error('Error fetching saved filters:', err);
      }
    };

    fetchSavedFilters();
  }, []);

  // Aktuellen Filter speichern
  const saveCurrentFilter = async () => {
    if (!filterName) return;
    const newFilter = { name: filterName, filters };

    try {
      const response = await axios.post(`${API_URL}/api/filters`, newFilter, {
        withCredentials: true,
      });
      setSavedFilters([...savedFilters, { ...newFilter, id: response.data.filter_id }]);
      setFilterName('');
    } catch (err) {
      console.error('Error saving filter:', err);
    }
  };

  // Gespeicherten Filter anwenden
  const applySavedFilter = (filterId) => {
    if (filterId === '') {
      // Keine Auswahl, Filter zurücksetzen
      setFilters({
        priceOperator: '',
        priceValue1: '',
        priceValue2: '',
        purchasePriceOperator: '',
        purchasePriceValue1: '',
        purchasePriceValue2: '',
        stockOperator: '',
        stockValue: '',
        active: '',
      });
      setSelectedFilter('');
      setCurrentPage(1);
      return;
    }

    const filter = savedFilters.find((f) => f.id === parseInt(filterId, 10));
    if (filter) {
      setFilters(filter.filters);
      setSelectedFilter(filter.id.toString());
      setCurrentPage(1);
    }
  };

  // Filter löschen
  const deleteFilter = async (filterId) => {
    try {
      await axios.delete(`${API_URL}/api/filters/${filterId}`, {
        withCredentials: true,
      });
      setSavedFilters(savedFilters.filter((f) => f.id !== filterId));
      if (selectedFilter === filterId.toString()) {
        setSelectedFilter('');
        // Filter zurücksetzen
        setFilters({
          priceOperator: '',
          priceValue1: '',
          priceValue2: '',
          purchasePriceOperator: '',
          purchasePriceValue1: '',
          purchasePriceValue2: '',
          stockOperator: '',
          stockValue: '',
          active: '',
        });
      }
    } catch (err) {
      console.error('Error deleting filter:', err);
    }
  };

  // Produktfeldänderungen handhaben
  const handleProductChange = (fieldName, value) => {
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [fieldName]: value,
    }));
  };

  // Bearbeitung eines Produkts starten
  const startEditingProduct = (product) => {
    setEditingProductId(product.id);
    setEditedProduct({ ...product }); // Kopie des Produkts zum Bearbeiten
  };

  // Produktänderungen speichern
  const saveProductChanges = (selectedActions) => {
    // Senden des bearbeiteten Produkts und der Aktionen an die API
    const payload = {
      ...editedProduct,
      actions: selectedActions,
    };

    axios
      .post('https://automate.vsrv.bcdevelopment.io/webhook/api/actions', payload, {
        params: { baseurl: shopUrl },
      })
      .then((response) => {
        console.log('Erfolgreich gesendet:', response.data);
        // Aktualisieren Sie die Produktliste, falls erforderlich
        // Beenden des Bearbeitungsmodus
        setEditingProductId(null);
        setEditedProduct({});
      })
      .catch((error) => {
        console.error('Fehler beim Senden:', error);
      });
  };

  // Bearbeitung abbrechen
  const cancelEditing = () => {
    setEditingProductId(null);
    setEditedProduct({});
  };

  // Gefilterte Produkte
  const filteredProducts = useMemo(() => {
    let filtered = products.filter((product) => {
      const lowercasedQuery = searchQuery.toLowerCase();
      const matchesSearch =
        (product.name || '').toLowerCase().includes(lowercasedQuery) ||
        (product.productNumber || '').toLowerCase().includes(lowercasedQuery) ||
        (product.ean && product.ean.includes(searchQuery));

      return matchesSearch;
    });

    filtered = filtered.filter((product) => {
      let matches = true;

      // Preisfilter
      if (filters.priceOperator && filters.priceValue1 !== '') {
        const price = parseFloat(product.gross);
        const value1 = parseFloat(filters.priceValue1);
        const value2 = parseFloat(filters.priceValue2);

        switch (filters.priceOperator) {
          case 'equals':
            matches = matches && price === value1;
            break;
          case 'greater':
            matches = matches && price > value1;
            break;
          case 'greater_equal':
            matches = matches && price >= value1;
            break;
          case 'less':
            matches = matches && price < value1;
            break;
          case 'less_equal':
            matches = matches && price <= value1;
            break;
          case 'between':
            matches = matches && price >= value1 && price <= value2;
            break;
          default:
            break;
        }
      }

      // Einkaufspreisfilter
      if (filters.purchasePriceOperator && filters.purchasePriceValue1 !== '') {
        const purchasePrice = parseFloat(product.purchasePricesnet);
        const value1 = parseFloat(filters.purchasePriceValue1);
        const value2 = parseFloat(filters.purchasePriceValue2);

        switch (filters.purchasePriceOperator) {
          case 'equals':
            matches = matches && purchasePrice === value1;
            break;
          case 'greater':
            matches = matches && purchasePrice > value1;
            break;
          case 'greater_equal':
            matches = matches && purchasePrice >= value1;
            break;
          case 'less':
            matches = matches && purchasePrice < value1;
            break;
          case 'less_equal':
            matches = matches && purchasePrice <= value1;
            break;
          case 'between':
            matches = matches && purchasePrice >= value1 && purchasePrice <= value2;
            break;
          default:
            break;
        }
      }

      // Lagerbestandfilter
      if (filters.stockOperator && filters.stockValue !== '') {
        const stock = parseInt(product.stock, 10);
        const value = parseInt(filters.stockValue, 10);

        switch (filters.stockOperator) {
          case 'equals':
            matches = matches && stock === value;
            break;
          case 'greater':
            matches = matches && stock > value;
            break;
          case 'greater_equal':
            matches = matches && stock >= value;
            break;
          case 'less':
            matches = matches && stock < value;
            break;
          case 'less_equal':
            matches = matches && stock <= value;
            break;
          default:
            break;
        }
      }

      // Aktivfilter
      if (filters.active !== '') {
        matches = matches && product.active === (filters.active === 'true');
      }

      return matches;
    });

    // Sortierung
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Konvertieren von Booleschen Werten für 'active'
        if (sortConfig.key === 'active') {
          aValue = aValue ? 1 : 0;
          bValue = bValue ? 1 : 0;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filtered;
  }, [products, searchQuery, filters, sortConfig]);

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const paginatedProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredProducts.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredProducts, currentPage]);

  if (loading) {
    return <div>Lädt...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Kopfzeile */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Produkte</h1>
          <p className="mt-2 text-sm text-gray-700">
            Eine Liste der Produkte basierend auf der ausgewählten Organisation.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <input
            type="text"
            placeholder="Suche nach Name, Produktnummer oder EAN"
            value={searchQuery}
            onChange={handleSearch}
            className="block w-full sm:w-auto px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
          />
        </div>
      </div>

      {/* Organisationsauswahl */}
      <div className="mt-4">
        <label htmlFor="organization" className="block text-sm font-medium text-gray-700">
          Organisation auswählen
        </label>
        <select
          id="organization"
          value={selectedOrgId}
          onChange={handleOrganizationChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Bitte wählen...</option>
          {organizations.map((org) => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </select>
      </div>

      {/* Gespeicherte Filter */}
      <div className="mt-4">
        <label htmlFor="savedFilters" className="block text-sm font-medium text-gray-700">
          Gespeicherte Filter
        </label>
        <div className="flex items-center">
          <select
            id="savedFilters"
            value={selectedFilter}
            onChange={(e) => applySavedFilter(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Keine Auswahl</option>
            {savedFilters.map((filter) => (
              <option key={filter.id} value={filter.id}>
                {filter.name}
              </option>
            ))}
          </select>
          {selectedFilter && (
            <button
              onClick={() => deleteFilter(parseInt(selectedFilter, 10))}
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded-md"
            >
              Löschen
            </button>
          )}
        </div>
      </div>

      {/* Filter */}
      <div className="mt-4 grid grid-cols-4 gap-4">
        {/* Preisfilter */}
        <div>
          <label htmlFor="priceOperator" className="block text-sm font-medium text-gray-700">
            Preisfilter
          </label>
          <select
            name="priceOperator"
            id="priceOperator"
            value={filters.priceOperator}
            onChange={handleFilterChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
          >
            <option value="">Wählen...</option>
            <option value="equals">Ist gleich</option>
            <option value="greater">Größer als</option>
            <option value="greater_equal">Größer oder gleich</option>
            <option value="less">Kleiner als</option>
            <option value="less_equal">Kleiner oder gleich</option>
            <option value="between">Zwischen</option>
          </select>
        </div>
        <div>
          <label htmlFor="priceValue1" className="block text-sm font-medium text-gray-700">
            Preiswert 1
          </label>
          <input
            type="number"
            name="priceValue1"
            id="priceValue1"
            value={filters.priceValue1}
            onChange={handleFilterChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
          />
        </div>
        {filters.priceOperator === 'between' && (
          <div>
            <label htmlFor="priceValue2" className="block text-sm font-medium text-gray-700">
              Preiswert 2
            </label>
            <input
              type="number"
              name="priceValue2"
              id="priceValue2"
              value={filters.priceValue2}
              onChange={handleFilterChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
            />
          </div>
        )}

        {/* Einkaufspreisfilter */}
        <div>
          <label htmlFor="purchasePriceOperator" className="block text-sm font-medium text-gray-700">
            Einkaufspreisfilter
          </label>
          <select
            name="purchasePriceOperator"
            id="purchasePriceOperator"
            value={filters.purchasePriceOperator}
            onChange={handleFilterChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
          >
            <option value="">Wählen...</option>
            <option value="equals">Ist gleich</option>
            <option value="greater">Größer als</option>
            <option value="greater_equal">Größer oder gleich</option>
            <option value="less">Kleiner als</option>
            <option value="less_equal">Kleiner oder gleich</option>
            <option value="between">Zwischen</option>
          </select>
        </div>
        <div>
          <label htmlFor="purchasePriceValue1" className="block text-sm font-medium text-gray-700">
            Einkaufspreiswert 1
          </label>
          <input
            type="number"
            name="purchasePriceValue1"
            id="purchasePriceValue1"
            value={filters.purchasePriceValue1}
            onChange={handleFilterChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
          />
        </div>
        {filters.purchasePriceOperator === 'between' && (
          <div>
            <label htmlFor="purchasePriceValue2" className="block text-sm font-medium text-gray-700">
              Einkaufspreiswert 2
            </label>
            <input
              type="number"
              name="purchasePriceValue2"
              id="purchasePriceValue2"
              value={filters.purchasePriceValue2}
              onChange={handleFilterChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
            />
          </div>
        )}

        {/* Lagerbestandfilter */}
        <div>
          <label htmlFor="stockOperator" className="block text-sm font-medium text-gray-700">
            Lagerbestandfilter
          </label>
          <select
            name="stockOperator"
            id="stockOperator"
            value={filters.stockOperator}
            onChange={handleFilterChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
          >
            <option value="">Wählen...</option>
            <option value="equals">Ist gleich</option>
            <option value="greater">Größer als</option>
            <option value="greater_equal">Größer oder gleich</option>
            <option value="less">Kleiner als</option>
            <option value="less_equal">Kleiner oder gleich</option>
          </select>
        </div>
        <div>
          <label htmlFor="stockValue" className="block text-sm font-medium text-gray-700">
            Lagerbestand
          </label>
          <input
            type="number"
            name="stockValue"
            id="stockValue"
            value={filters.stockValue}
            onChange={handleFilterChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
          />
        </div>

        {/* Aktiv/Inaktiv */}
        <div>
          <label htmlFor="active" className="block text-sm font-medium text-gray-700">
            Aktiv/Inaktiv
          </label>
          <select
            name="active"
            id="active"
            value={filters.active}
            onChange={handleFilterChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
          >
            <option value="">Aktiv/Inaktiv</option>
            <option value="true">Aktiv</option>
            <option value="false">Inaktiv</option>
          </select>
        </div>
      </div>

      {/* Filter speichern */}
      <div className="mt-4 flex items-center">
        <input
          type="text"
          placeholder="Filtername"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          className="block w-full sm:w-auto px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
        />
        <button
          onClick={saveCurrentFilter}
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          Filter speichern
        </button>
      </div>

      {/* Produkttabelle */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-40"
                    >                      
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('ean')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer w-60"
                    >
                      Produktname
                      {sortConfig.key === 'name' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('ean')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      EAN (GTIN)
                      {sortConfig.key === 'ean' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('gross')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      VK-Preis (Brutto)
                      {sortConfig.key === 'gross' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('gross')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      VK-Preis (Netto)
                      {sortConfig.key === 'gross' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('net')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      EK-Preis (Netto)
                      {sortConfig.key === 'net' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('stock')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      Lagerbestand
                      {sortConfig.key === 'stock' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('active')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      Aktiv
                      {sortConfig.key === 'active' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Bearbeiten
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {paginatedProducts.map((product) => (
                    <React.Fragment key={product.id}>
                      <tr
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => toggleRow(product.id)}
                      >
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-center max-w-xs overflow-hidden">
                          <img
                            src={product.picture || 'https://via.placeholder.com/50'}
                            alt={product.name}
                            className="w-auto h-20 max-w-md rounded-lg object-cover"
                          />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs overflow-hidden">
                          {product.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {product.ean}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {product.gross} €
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {(product.gross / 1.19).toFixed(2)} €
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {product.purchasePricesnet ? (
                            product.purchasePricesnet.toFixed(2)
                          ) : (
                            0                          
                          )} €
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {product.stock}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {product.active ? (
                            <CheckCircleIcon className="h-6 w-6 text-green-500" />
                          ) : (
                            <XCircleIcon className="h-6 w-6 text-red-500" />
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Verhindert das Ausklappen der Zeile
                              startEditingProduct(product);
                            }}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Bearbeiten
                          </button>
                        </td>
                      </tr>

                      {expandedRows.includes(product.id) && (
                        <tr className="bg-gray-100">
                          <td colSpan="8" className="p-4">
                            {editingProductId === product.id ? (
                              <EditProductForm
                                product={editedProduct}
                                onChange={handleProductChange}
                                onSave={saveProductChanges}
                                onCancel={cancelEditing}
                              />
                            ) : (
                              <div className="grid grid-cols-3 gap-6 bg-white p-6 rounded-lg shadow-md">
                                {/* Bild */}
                                <div className="flex-shrink-0">
                                  <img
                                    src={product.picture || 'https://via.placeholder.com/300'}
                                    alt={product.name}
                                    className="w-full h-auto max-w-md rounded-lg object-cover"
                                  />
                                </div>
                            
                                {/* Produktinformationen */}
                                <div className="col-span-2 grid grid-cols-3 gap-4">
                                  {/* Spalte 1: Produktnummer und Herstellernummer */}
                                  <div>
                                    <p className="text-sm font-medium text-gray-900">
                                      <strong>Produktnummer:</strong> {product.productNumber}
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      <strong>Herstellernummer:</strong> {product.manufacturerNumber}
                                    </p>
                                    <p className="text-sm text-gray-900">
                                      <strong>Herseller:</strong> {product.manufacturername}
                                    </p><br></br>
                                    <p className="text-sm text-gray-900">
                                      <strong>Lieferanteninformationen:</strong>
                                    </p>
                                    {product.supplier && product.supplier.length > 0 ? (
                                      <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                                          <thead className="bg-gray-200">
                                            <tr>
                                              <th className="px-4 py-2 text-left text-sm font-medium text-gray-900 border-b border-gray-300">Name</th>
                                              <th className="px-4 py-2 text-left text-sm font-medium text-gray-900 border-b border-gray-300">Preis</th>
                                              <th className="px-4 py-2 text-left text-sm font-medium text-gray-900 border-b border-gray-300">Lagerbestand</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {product.supplier.map((supplier, index) => (
                                              <tr key={index}>
                                                <td className="px-4 py-2 text-sm text-gray-700 border-b border-gray-300">{supplier.name}</td>
                                                <td className="px-4 py-2 text-sm text-gray-700 border-b border-gray-300">{supplier.price}</td>
                                                <td className="px-4 py-2 text-sm text-gray-700 border-b border-gray-300">{supplier.stock}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    ) : (
                                      <p className="text-sm text-gray-500">Keine Lieferanteninformationen verfügbar</p>
                                    )}

                                  </div>
                            
                                  {/* Spalte 2: Beschreibung */}
                                  <div>
                                    <p className="text-sm font-medium text-gray-900">
                                      <strong>Beschreibung:</strong>
                                    </p>
                                    {product.description ? (
                                      <div
                                        className="text-sm text-gray-500"
                                        dangerouslySetInnerHTML={{ __html: product.description }}
                                      />
                                    ) : (
                                      <p className="text-sm text-gray-500">Keine Beschreibung verfügbar</p>
                                    )}
                                  </div>
                                  
                                  {/* Spalte 3: Custom Fields */}
                                  {product.customFields && (
                                    <div>
                                      <p className="text-sm font-medium text-gray-900">
                                        <strong>Custom Fields:</strong>
                                      </p>
                                      <ul className="list-disc pl-5 text-sm text-gray-500">
                                        {Object.entries(product.customFields).map(([key, value]) => (
                                          <li key={key}>
                                            <strong>{key}:</strong>{' '}
                                            {key === 'custom_description_short_description' ?(
                                              <span
                                                dangerouslySetInnerHTML={{__html: value }}
                                                />
                                            ) :(
                                              value
                                            )}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Paginierung */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-300 rounded-md disabled:opacity-50"
          >
            Zurück
          </button>
          <span>
            Seite {currentPage} von {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-300 rounded-md disabled:opacity-50"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
