import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckIcon, XCircleIcon } from '@heroicons/react/20/solid';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(null); // null: noch nicht geprüft, true: stimmt überein, false: stimmt nicht überein
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  // Funktion zur Überprüfung, ob die Passwörter übereinstimmen
  const checkPasswordsMatch = (password, confirmPassword) => {
    if (confirmPassword === '') {
      setPasswordsMatch(null);
    } else if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  // Event-Handler für Passwortfeld
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordsMatch(e.target.value, confirmPassword);
  };

  // Event-Handler für Passwortbestätigungsfeld
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordsMatch(password, e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordsMatch !== true) {
      // Passwörter stimmen nicht überein, zeige Fehlermeldung
      setMessage('Die Passwörter stimmen nicht überein.');
      return;
    }

    const response = await fetch(`${API_URL}/reset_password/${encodeURIComponent(token)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });

    if (response.ok) {
      const data = await response.json();
      setMessage(data.msg);
      setTimeout(() => navigate('/Login'), 5000);
    } else {
      const data = await response.json();
      setMessage(data.msg);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          alt="Your Company"
          src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
          Neues Passwort festlegen
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Passwort-Feld */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                Neues Passwort
              </label>
              <div className="mt-2 relative">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="new-password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm 
                             ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                             focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Passwortbestätigung-Feld */}
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-900">
                Passwort bestätigen
              </label>
              <div className="mt-2 relative">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className={`block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ${
                    passwordsMatch === false
                      ? 'ring-red-500 focus:ring-red-500'
                      : 'ring-gray-300 focus:ring-indigo-600'
                  } placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
                {/* Icon anzeigen, wenn Passwörter verglichen wurden */}
                {passwordsMatch !== null && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {passwordsMatch ? (
                      <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                    ) : (
                      <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    )}
                  </div>
                )}
              </div>
              {/* Fehlermeldung anzeigen, wenn Passwörter nicht übereinstimmen */}
              {passwordsMatch === false && (
                <p className="mt-2 text-sm text-red-600">Die Passwörter stimmen nicht überein.</p>
              )}
            </div>

            {/* Fehlermeldung aus dem Server */}
            {message && (
              <p className="text-sm text-red-600">{message}</p>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 
                           text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 
                           focus:ring-2 focus:ring-indigo-600"
              >
                Passwort zurücksetzen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
