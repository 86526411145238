import React from 'react';

const Home = () => {
  return (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto text-center">
        <h1 className="text-4xl font-bold text-gray-900">Willkommen zu MyApp</h1>
        <p className="mt-4 text-gray-600">Verwalten Sie Ihre Daten effizient und einfach mit unserer Plattform.</p>
        <div className="mt-8">
          <a href="/login" className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-500">Login</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
