import React, { useState } from 'react';

const EditProductForm = ({ product, onChange, onSave, onCancel }) => {
  const [selectedActions, setSelectedActions] = useState([]);

  // Liste der bearbeitbaren Felder
//  const editableFields = [
//    'description',
//    'metaTitle',
//    'metaDescription',
//    'shortDescription',
//    // Weitere Felder können hinzugefügt werden
//  ];

  // Liste der verfügbaren Aktionen
  const availableActions = [
    { value: 'createDescription', label: 'Beschreibung erstellen' },
    { value: 'createMetaTitle', label: 'Meta-Titel erstellen' },
    { value: 'createMetaDescription', label: 'Meta-Beschreibung erstellen' },
    { value: 'createShortDescription', label: 'Kurzbeschreibung erstellen' },
    { value: 'findProductImage', label: 'Produkbild suchen' },
  ];

//  const handleFieldChange = (e) => {
//    const { name, value } = e.target;
//    onChange(name, value);
//  };

  const handleActionChange = (e) => {
    const { value, checked } = e.target;
    setSelectedActions((prev) =>
      checked ? [...prev, value] : prev.filter((action) => action !== value)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(selectedActions);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* 3-Spalten-Grid für Aktionen, Beschreibung und restliche Felder */}
      <div className="grid grid-cols-5 gap-4">
        {/* Aktionen (Links) */}
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700">
            Aktionen auswählen
          </label>
          {availableActions.map((action) => (
            <div key={action.value} className="flex items-center">
              <input
                type="checkbox"
                value={action.value}
                onChange={handleActionChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-700">
                {action.label}
              </label>
            </div>
          ))}
        </div>

        {/* Beschreibung (Mitte) */}
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Beschreibung
          </label>
          <div
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
            dangerouslySetInnerHTML={{ __html: product.description || 'N/A' }}
          />
        </div>

        {/* Restliche Felder (Rechts) */}
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">
            Meta-Titel
          </label>
          <div
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
            dangerouslySetInnerHTML={{ __html: product.metaTitle || 'N/A' }}
          />
          <label className="block text-sm font-medium text-gray-700 mt-4">
            Meta-Beschreibung
          </label>
          <div
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
            dangerouslySetInnerHTML={{ __html: product.metaDescription || 'N/A' }}
          />
          <label className="block text-sm font-medium text-gray-700 mt-4">
            Kurzbeschreibung
          </label>
          <div
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
            dangerouslySetInnerHTML={{ __html: product.customFields?.custom_description_short_description || 'N/A' }}
          />
        </div>
      </div>

      {/* Speichern und Abbrechen Buttons */}
      <div className="mt-4 flex space-x-2">
        <button
          type="submit"
          className="px-4 py-2 bg-green-500 text-white rounded-md"
        >
          Speichern
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-500 text-white rounded-md"
        >
          Abbrechen
        </button>
      </div>
    </form>
  );
};

export default EditProductForm;
