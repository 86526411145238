import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, HomeIcon, FolderIcon, DocumentDuplicateIcon, Cog6ToothIcon, TruckIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const navigation = [
  { name: 'Dashboard', href: '/Dashboard', icon: HomeIcon },
  { name: 'Produkte', href: '/Produkte', icon: FolderIcon },
  { name: 'Pipeline (in Progress)', href: '/Pipeline', icon: DocumentDuplicateIcon },
  { name: 'Lieferanten', href: '/Supplier', icon: TruckIcon },
  { name: 'Settings', href: '/Settings', icon: Cog6ToothIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar({ collapsed, setCollapsed }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Interceptor für 401 Fehler
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          navigate('/login'); // Redirect to login
        }
        return Promise.reject(error);
      }
    );

    // Cleanup für den Interceptor bei Komponententrennung
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  const handleLogout = () => {
    axios
      .post(`${API_URL}/logout`, {}, { withCredentials: true }) // Make sure to send credentials
      .then(() => {
        window.location.href = '/login';  // Redirect to login page after successful logout
      })
      .catch((error) => {
        console.log('Error during logout:', error);
      });
  };

  return (
    <>
      {/* Static sidebar for desktop */}
      <div className={`hidden lg:flex lg:flex-col lg:fixed lg:inset-y-0 ${collapsed ? 'lg:w-20' : 'lg:w-72'} lg:z-50 lg:flex lg:transition-all lg:duration-300`}>
        <div className="flex flex-col flex-grow gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 relative">
          {/* Logo und Collapse Button */}
          <div className="flex items-center justify-between h-16 shrink-0">
            <img
              className={`h-8 w-auto transition-opacity ${collapsed ? 'opacity-0' : 'opacity-100'}`}
              src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
          </div>

          {/* Collapse Button (Pfeil) */}
          <div className="absolute inset-y-0 right-0 flex items-center justify-center w-6 bg-gray-200">
            <button
              onClick={() => setCollapsed(!collapsed)}
              className="flex items-center justify-center h-12 w-full rounded-md text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              {collapsed ? <ChevronDoubleRightIcon className="h-6 w-6" /> : <ChevronDoubleLeftIcon className="h-6 w-6" />}
            </button>
          </div>

          {/* Navigation */}
          <nav className="flex-1">
            <ul className="space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className={classNames(
                      location.pathname === item.href
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                      'group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                    )}
                  >
                    <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    {!collapsed && item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>

          {/* User-Bereich */}
          <div className="-mx-6 mt-auto">
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold w-full leading-6 text-gray-900 hover:bg-gray-50 hover:text-indigo-600">
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt="User profile"
                  />
                  {!collapsed && (
                    <>
                      <span className="sr-only">Open user menu</span>
                      <span aria-hidden="true">Philipp Flaum</span>
                    </>
                  )}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Menu.Items className="absolute left-12 bottom-12 z-10 w-auto origin-bottom-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/profile"
                        className={classNames(
                          active ? 'bg-gray-100 hover:bg-gray-50 hover:text-indigo-600' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        Dein Profil
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleLogout}
                        className={classNames(
                          active ? 'bg-gray-100 hover:bg-gray-50 hover:text-indigo-600' : '',
                          'block w-full text-left px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        Log out
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
