import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';



const BackendLayout = () => {
    const [collapsed, setCollapsed] = useState(false); // Zustand für eingeklappte Sidebar
  
    return (
      <div className="flex">
        {/* Sidebar */}
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
  
        {/* Main Content */}
        <div className={`flex-1 transition-all duration-300 ${collapsed ? 'lg:pl-20' : 'lg:pl-72'}`}> {/* Dynamische Verschiebung */}
          <main className="p-4">
            <Outlet />
          </main>
        </div>
      </div>
    );
  };
  
  export default BackendLayout;