import React, { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const Settings = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [shopUrl, setShopUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [clientId, setClientId] = useState('');  // Neuen State für Client ID hinzufügen


  useEffect(() => {
    // Daten von der Backend-API abrufen
    fetch(`${API_URL}/api/settings`, {
      method: 'GET',
      credentials: 'include',  // Cookies mitsenden
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Fehler beim Abrufen der Daten');
        }
      })
      .then((data) => {
        setOrganizations(data.organizations);
      })
      .catch((error) => {
        console.error('Fehler:', error);
      });
  }, []);

  const handleOrganizationChange = (e) => {
    const selectedOrgId = parseInt(e.target.value, 10);
    const selectedOrg = organizations.find(org => org.id === selectedOrgId);
  
    if (selectedOrg) {
      setSelectedOrgId(selectedOrg.id);
      setShopUrl(selectedOrg.shop_url || '');  // Fallback zu einer leeren Zeichenkette
      setApiKey(selectedOrg.api_key || '');    // Fallback zu einer leeren Zeichenkette
      setClientId(selectedOrg.client_id || '');  
      setIsAdmin(selectedOrg.is_admin);
    } else {
      console.error('Organisation nicht gefunden.');
      setSelectedOrgId('');
      setShopUrl('');
      setApiKey('');
      setClientId('');
      setIsAdmin(false);
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAdmin) {
      alert('Sie haben keine Berechtigung, diese Organisation zu bearbeiten.');
      return;
    }
  
    try {
      const response = await fetch(`${API_URL}/api/settings`, {
        method: 'POST',
        credentials: 'include',  // Cookies mitsenden
        headers: {
          'Content-Type': 'application/json',  // Setze den Content-Type für JSON-Daten
        },
        body: JSON.stringify({
          organization_id: selectedOrgId,
          shop_url: shopUrl,
          api_key: apiKey,
          client_id: clientId,
        }),
      });
  
      if (response.ok) {
        alert('Einstellungen erfolgreich aktualisiert!');
      } else {
        alert('Fehler beim Speichern der Einstellungen.');
      }
    } catch (error) {
      console.error('Fehler beim Speichern der Einstellungen:', error);
    }
  };
  

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Einstellungen</h1>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="organization" className="block text-sm font-medium text-gray-700">
            Organisation auswählen
          </label>
          <select
            id="organization"
            value={selectedOrgId}
            onChange={handleOrganizationChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          >
            <option value="">Bitte wählen...</option>
            {organizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name} {org.is_admin ? "(Admin)" : ""}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="shop_url" className="block text-sm font-medium text-gray-700">
            Shop-URL
          </label>
          <input
            type="url"
            id="shop_url"
            value={shopUrl}
            onChange={(e) => setShopUrl(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
            disabled={!isAdmin}  /* Nur Admins dürfen bearbeiten */
          />
        </div>

        <div>
          <label htmlFor="api_key" className="block text-sm font-medium text-gray-700">
            API-Schlüssel
          </label>
          <input
            type="text"
            id="api_key"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
            disabled={!isAdmin}  /* Nur Admins dürfen bearbeiten */
          />
        </div>

        <div>
          <label htmlFor="client_id" className="block text-sm font-medium text-gray-700">
            Zugangsid (Client ID)
          </label>
          <input
            type="text"
            id="client_id"
            value={clientId}  // Maskierte Darstellung
            onChange={(e) => setClientId(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
            disabled={!isAdmin}  // Nur Admins dürfen bearbeiten
          />
        </div>

        {isAdmin && (
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Einstellungen speichern
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Settings;
