// DataStreamConfigurator.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
function DataStreamConfigurator({ fields, dataStreamId, onMappingSaved }) {
  const [mappings, setMappings] = useState({});
  const [loadedFields, setLoadedFields] = useState(fields || []);
  const navigate = useNavigate();

  useEffect(() => {
    if ((!fields || fields.length === 0) && dataStreamId) {
      // Lade die Felder und das Mapping vom Backend
      fetch(`${API_URL}/api/data-streams/${dataStreamId}/fields-and-mapping`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.fields) {
            setLoadedFields(data.fields);
          }
          if (data.mapping) {
            setMappings(data.mapping);
          }
        })
        .catch((error) => console.error('Fehler beim Laden der Daten:', error));
    }
  }, [fields, dataStreamId]);

  if (!loadedFields || loadedFields.length === 0) {
    return <div>Keine Felder zum Anzeigen</div>;
  }

  const handleMappingChange = (field, value) => {
    setMappings((prevMappings) => ({
      ...prevMappings,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sende das Mapping an das Backend
    const response = await fetch(`${API_URL}/save-mapping`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        data_stream_id: dataStreamId,
        mapping: mappings,
        fields: fields,
      }),
    });
    console.log(fields)

    const result = await response.json();

    if (response.ok) {
      // Erfolgshandhabung
      console.log('Mapping gespeichert:', result);
      // Rufe die Callback-Funktion auf, um den Zustand in der Pipeline zu aktualisieren
      if (onMappingSaved) {
        onMappingSaved();
      } else {
        navigate('/pipeline');
      }
    } else {
      // Fehlerbehandlung
      console.error('Fehler beim Speichern des Mappings:', result);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Mapping erstellen</h2>
      <form onSubmit={handleSubmit}>
        {loadedFields.map((field) => (
          <div key={field} className="mb-4">
            <label className="block mb-2">{field}:</label>
            <input
              type="text"
              value={mappings[field] || ''}
              onChange={(e) => handleMappingChange(field, e.target.value)}
              className="border p-2 w-full"
            />
          </div>
        ))}
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          Mapping speichern
        </button>
      </form>
    </div>
  );
}

export default DataStreamConfigurator;
