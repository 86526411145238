import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FrontendLayout from './layouts/FrontendLayout';
import BackendLayout from './layouts/BackendLayout';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Produkte from './pages/Produkte';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import Pipeline from './pages/Pipeline';
import Supplier from './pages/Supplier';


function App() {
  return (
    <Router>
      <Routes>
        {/* Öffentliche Routen */}
        <Route element={<FrontendLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/reset_password" element={<ResetPasswordRequest />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
        </Route>

        {/* Geschützte Routen */}
        <Route element={<ProtectedRoute />}>
          <Route element={<BackendLayout />}>
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Produkte" element={<Produkte />} />
            <Route path="/Pipeline" element={<Pipeline />} />
            <Route path="/Supplier" element={<Supplier />} />
            {/* Weitere geschützte Routen */}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;