import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
    ChevronDownIcon,
    ChevronUpIcon,
  } from '@heroicons/react/24/solid';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ExtendedProductsPage = () => {
  // State variables
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [products, setProducts] = useState([]);
  const [filterRows, setFilterRows] = useState([{ field: '', operator: '', value1: '', value2: '' }]);
  const [filters, setFilters] = useState({});
  const [savedFilters, setSavedFilters] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 20;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Filterable fields and operators
  const filterableFields = useMemo(() => [
    { label: 'Produktname', value: 'title', type: 'string' },
    { label: 'SKU', value: 'sku', type: 'string' },
    { label: 'Preis', value: 'price', type: 'number' },
    { label: 'Lagerbestand', value: 'stock', type: 'number' },
    { label: 'EAN', value: 'ean', type: 'string' },
    { label: 'Hersteller', value: 'manufacturer', type: 'string' },
    { label: 'In Shopware', value: 'isinshopware', type: 'boolean' },
    { label: 'Aktiv', value: 'active', type: 'boolean' },
  ],[]);

  const operatorsByType = {
    string: [
      { label: 'Enthält', value: 'contains' },
      { label: 'Enthält nicht', value: 'not_contains' },
      { label: 'Ist gleich', value: 'equals' },
      { label: 'Ist nicht gleich', value: 'not_equals' },
    ],
    number: [
      { label: 'Ist gleich', value: 'equals' },
      { label: 'Ist nicht gleich', value: 'not_equals' },
      { label: 'Größer als', value: 'greater_than' },
      { label: 'Kleiner als', value: 'less_than' },
      { label: 'Größer oder gleich', value: 'greater_equal' },
      { label: 'Kleiner oder gleich', value: 'less_equal' },
      { label: 'Zwischen', value: 'between' },
    ],
    boolean: [
      { label: 'Ist', value: 'equals' },
      { label: 'Ist nicht', value: 'not_equals' },
    ],
  };

  // Load organizations on mount
  useEffect(() => {
    axios
      .get(`${API_URL}/api/settings`, { withCredentials: true })
      .then((response) => {
        setOrganizations(response.data.organizations);
      })
      .catch((error) => console.error(error));
  }, []);

  // Load saved filters on mount
  useEffect(() => {
    const fetchSavedFilters = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/filters`, { withCredentials: true });
        setSavedFilters(response.data.filters);
      } catch (err) {
        console.error('Error fetching saved filters:', err);
      }
    };
    fetchSavedFilters();
  }, []);

  // Handle organization change
  const handleOrganizationChange = (e) => {
    const orgId = e.target.value;
    const selectedOrg = organizations.find((org) => org.id === parseInt(orgId, 10));
    if (selectedOrg) {
      setSelectedOrg(selectedOrg);
      setSelectedSupplier('');
      setSelectedManufacturer('');
      setManufacturers([]);
      setProducts([]);
      setFilterRows([{ field: '', operator: '', value1: '', value2: '' }]);
      setFilters({});
      setCurrentPage(1);
      fetchSuppliers(selectedOrg.shop_url);
    }
  };

  // Fetch suppliers
  const fetchSuppliers = (shopUrl) => {
    setLoading(true);
    axios
      .get('https://automate.vsrv.bcdevelopment.io/webhook/083dff1b-90cd-4b1e-a82a-de0717770481', {
        params: { baseurl: shopUrl },
      })
      .then((response) => {
        setSuppliers(response.data[0].data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Fehler beim Laden der Lieferanten.');
        setLoading(false);
      });
  };

  // Handle supplier change
  const handleSupplierChange = (e) => {
    const supplier = e.target.value;
    setSelectedSupplier(supplier);
    setSelectedManufacturer('');
    setManufacturers([]);
    setProducts([]);
    setFilterRows([{ field: '', operator: '', value1: '', value2: '' }]);
    setFilters({});
    setCurrentPage(1);
    if (selectedOrg) {
      fetchManufacturers(selectedOrg.shop_url, supplier);
    }
  };

  // Fetch manufacturers
  const fetchManufacturers = (shopUrl, supplier) => {
    setLoading(true);
    axios
      .get('https://automate.vsrv.bcdevelopment.io/webhook/c31a3e02-0b66-42c6-be8f-381d4babd92f', {
        params: { baseurl: shopUrl, supplier },
      })
      .then((response) => {
        setManufacturers(response.data[0].manufacturer);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Fehler beim Laden der Hersteller.');
        setLoading(false);
      });
  };

  // Handle manufacturer change
  const handleManufacturerChange = (e) => {
    const manufacturer = e.target.value;
    setSelectedManufacturer(manufacturer);
    setProducts([]);
    setFilterRows([{ field: '', operator: '', value1: '', value2: '' }]);
    setFilters({});
    setCurrentPage(1);
    if (selectedOrg && selectedSupplier) {
      fetchProducts(selectedOrg.shop_url, selectedSupplier, manufacturer);
    }
  };

  // Fetch products
  const fetchProducts = (shopUrl, supplier, manufacturer) => {
    setLoading(true);
    axios
      .get('https://automate.vsrv.bcdevelopment.io/webhook/d52593a7-1282-4033-b04f-1a6ca88e3bda', {
        params: { baseurl: shopUrl, supplier, brand: manufacturer },
      })
      .then((response) => {
        const products = response.data.map((product) => ({
          ...product,
          isinshopware: product.isinshopware || false,
          active: product.active || false,
          stock: product.stock || 0,
        }));
        setProducts(products);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError('Fehler beim Laden der Produkte.');
        setLoading(false);
      });
  };

  // Handle filter row change
  const handleFilterRowChange = (index, field, value) => {
    const newFilterRows = [...filterRows];
    newFilterRows[index][field] = value;
    setFilterRows(newFilterRows);

    // Update filters state
    const updatedFilters = {};
    newFilterRows.forEach((row) => {
      if (row.field && row.operator && row.value1 !== '') {
        updatedFilters[row.field] = {
          operator: row.operator,
          value1: row.value1,
          value2: row.value2,
        };
      }
    });
    setFilters(updatedFilters);
    setCurrentPage(1);
  };

  // Add filter row
  const addFilterRow = () => {
    setFilterRows([...filterRows, { field: '', operator: '', value1: '', value2: '' }]);
  };

  // Remove filter row
  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((_, i) => i !== index);
    setFilterRows(newFilterRows);

    // Update filters state
    const updatedFilters = {};
    newFilterRows.forEach((row) => {
      if (row.field && row.operator && row.value1 !== '') {
        updatedFilters[row.field] = {
          operator: row.operator,
          value1: row.value1,
          value2: row.value2,
        };
      }
    });
    setFilters(updatedFilters);
    setCurrentPage(1);
  };

  // Save current filters
  const saveCurrentFilter = async () => {
    if (!filterName) return;
    const newFilter = { name: filterName, filters };

    try {
      const response = await axios.post(`${API_URL}/api/filters`, newFilter, {
        withCredentials: true,
      });
      setSavedFilters([...savedFilters, { ...newFilter, id: response.data.filter_id }]);
      setFilterName('');
    } catch (err) {
      console.error('Error saving filter:', err);
    }
  };

  // Apply saved filter
  const applySavedFilter = (filterId) => {
    if (filterId === '') {
      // No selection, reset filters
      setFilters({});
      setFilterRows([{ field: '', operator: '', value1: '', value2: '' }]);
      setSelectedFilter('');
      setCurrentPage(1);
      return;
    }

    const filter = savedFilters.find((f) => f.id === parseInt(filterId, 10));
    if (filter) {
      setFilters(filter.filters);
      setSelectedFilter(filter.id.toString());

      // Convert filters to filterRows
      const newFilterRows = Object.keys(filter.filters).map((field) => {
        const { operator, value1, value2 } = filter.filters[field];
        return { field, operator, value1, value2 };
      });
      setFilterRows(newFilterRows);
      setCurrentPage(1);
    }
  };

  // Delete saved filter
  const deleteFilter = async (filterId) => {
    try {
      await axios.delete(`${API_URL}/api/filters/${filterId}`, { withCredentials: true });
      setSavedFilters(savedFilters.filter((f) => f.id !== filterId));
      if (selectedFilter === filterId.toString()) {
        setSelectedFilter('');
        setFilters({});
        setFilterRows([{ field: '', operator: '', value1: '', value2: '' }]);
      }
    } catch (err) {
      console.error('Error deleting filter:', err);
    }
  };

  // Filtering logic with search
  const filteredProducts = useMemo(() => {
    let filtered = products.filter((product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (product.ean || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
      (product.manufacturerNumber || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    Object.keys(filters).forEach((field) => {
      const { operator, value1, value2 } = filters[field];
      filtered = filtered.filter((product) => {
        const fieldValue = product[field];
        const fieldType = filterableFields.find((f) => f.value === field)?.type || 'string';

        switch (operator) {
          case 'contains':
            return (
              fieldValue &&
              fieldValue.toString().toLowerCase().includes(value1.toLowerCase())
            );
          case 'not_contains':
            return (
              fieldValue &&
              !fieldValue.toString().toLowerCase().includes(value1.toLowerCase())
            );
          case 'equals':
            if (fieldType === 'boolean') {
              return fieldValue === (value1 === 'true');
            }
            return fieldValue && fieldValue.toString() === value1;
          case 'not_equals':
            if (fieldType === 'boolean') {
              return fieldValue !== (value1 === 'true');
            }
            return fieldValue && fieldValue.toString() !== value1;
          case 'greater_than':
            return parseFloat(fieldValue) > parseFloat(value1);
          case 'less_than':
            return parseFloat(fieldValue) < parseFloat(value1);
          case 'greater_equal':
            return parseFloat(fieldValue) >= parseFloat(value1);
          case 'less_equal':
            return parseFloat(fieldValue) <= parseFloat(value1);
          case 'between':
            return (
              parseFloat(fieldValue) >= parseFloat(value1) &&
              parseFloat(fieldValue) <= parseFloat(value2)
            );
          default:
            return true;
        }
      });
    });

    // Sorting
    if (sortConfig.key) {
      filtered.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filtered;
  }, [products, filters, sortConfig, searchQuery,filterableFields]);

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const paginatedProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredProducts.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredProducts, currentPage]);

  // Handle sort request
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Handle adding product to shop with additional data
  const handleAddToShop = (product) => {
    const dataToSend = {
      ean: product.ean,
      special_price: product.special_price ? product.special_price : product.price,
      title: product.title,
      msku: product.msku,
      stock: product.stock,
      lwg1: product.lwg1,
      manufacturer: product.manufacturer,
    };

    axios
      .post('https://automate.vsrv.bcdevelopment.io/webhook/add_to_shop', dataToSend)
      .then(() => alert(`Produkt ${product.title} übernommen`))
      .catch(() => alert(`Fehler bei der Übernahme des Produkts ${product.title}`));
  };

  // Render component
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Produktauswahl</h1>
          <p className="mt-2 text-sm text-gray-700">Produkte basierend auf Auswahlfiltern.</p>
        </div>
      </div>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {/* Organization selection */}
      <div className="mt-4">
        <label htmlFor="organization" className="block text-sm font-medium text-gray-700">
          Firma auswählen
        </label>
        <select
          id="organization"
          onChange={handleOrganizationChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
          value={selectedOrg?.id || ''}
        >
          <option value="">Bitte wählen...</option>
          {organizations.map((org) => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </select>
      </div>

      {/* Supplier selection */}
      {suppliers.length > 0 && (
        <div className="mt-4">
          <label htmlFor="supplier" className="block text-sm font-medium text-gray-700">
            Lieferant auswählen
          </label>
          <select
            id="supplier"
            onChange={handleSupplierChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
            value={selectedSupplier}
          >
            <option value="">Bitte wählen...</option>
            {suppliers.map((supplier, index) => (
              <option key={index} value={supplier}>
                {supplier}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Manufacturer selection */}
      {manufacturers.length > 0 && (
        <div className="mt-4">
          <label htmlFor="manufacturer" className="block text-sm font-medium text-gray-700">
            Hersteller auswählen
          </label>
          <select
            id="manufacturer"
            onChange={handleManufacturerChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
            value={selectedManufacturer}
          >
            <option value="">Bitte wählen...</option>
            {manufacturers.map((manufacturer, index) => (
              <option key={index} value={manufacturer}>
                {manufacturer}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Ladeindikator */}
      {loading && products.length === 0 && (
        <div className="mt-4 text-center">
          <p>Lädt Produkte...</p>
        </div>
      )}

      {/* Saved filters */}
      <div className="mt-4">
        <label htmlFor="savedFilters" className="block text-sm font-medium text-gray-700">
          Gespeicherte Filter
        </label>
        <div className="flex items-center">
          <select
            id="savedFilters"
            value={selectedFilter}
            onChange={(e) => applySavedFilter(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:outline-none"
          >
            <option value="">Kein Filter ausgeählt</option>
            {savedFilters.map((filter) => (
              <option key={filter.id} value={filter.id}>
                {filter.name}
              </option>
            ))}
          </select>
          {selectedFilter && (
            <button
              onClick={() => deleteFilter(parseInt(selectedFilter, 10))}
              className="ml-2 px-4 py-2 bg-red-500 text-white rounded-md"
            >
              Löschen
            </button>
          )}
        </div>
      </div>

      {/* Filter form */}
      <div className="mt-4 space-y-4">
        {filterRows.map((row, index) => {
          const fieldType = filterableFields.find((f) => f.value === row.field)?.type || 'string';
          return (
            <div key={index} className="flex space-x-2">
              <select
                value={row.field}
                onChange={(e) => handleFilterRowChange(index, 'field', e.target.value)}
                className="w-1/4 border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Feld wählen...</option>
                {filterableFields.map((field) => (
                  <option key={field.value} value={field.value}>
                    {field.label}
                  </option>
                ))}
              </select>
              <select
                value={row.operator}
                onChange={(e) => handleFilterRowChange(index, 'operator', e.target.value)}
                className="w-1/4 border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Operator wählen...</option>
                {operatorsByType[fieldType].map((operator) => (
                  <option key={operator.value} value={operator.value}>
                    {operator.label}
                  </option>
                ))}
              </select>
              <input
                type={fieldType === 'number' ? 'number' : 'text'}
                value={row.value1}
                onChange={(e) => handleFilterRowChange(index, 'value1', e.target.value)}
                placeholder="Wert 1"
                className="w-1/4 border-gray-300 rounded-md shadow-sm"
              />
              {row.operator === 'between' && (
                <input
                  type="number"
                  value={row.value2}
                  onChange={(e) => handleFilterRowChange(index, 'value2', e.target.value)}
                  placeholder="Wert 2"
                  className="w-1/4 border-gray-300 rounded-md shadow-sm"
                />
              )}
              <button
                onClick={() => removeFilterRow(index)}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Entfernen
              </button>
            </div>
          );
        })}
        <button onClick={addFilterRow} className="px-4 py-2 bg-green-500 text-white rounded-md">
          Filter hinzufügen
        </button>
      </div>

      {/* Save and clear filter buttons */}
      <div className="mt-4 flex space-x-2">
        <input
          type="text"
          placeholder="Filtername"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          className="flex-grow px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
        />
        <button onClick={saveCurrentFilter} className="px-4 py-2 bg-blue-500 text-white rounded-md">
          Filterung speichern
        </button>
        <button
          onClick={() => {
            setFilters({});
            setFilterRows([{ field: '', operator: '', value1: '', value2: '' }]);
          }}
          className="px-4 py-2 bg-gray-400 text-white rounded-md"
        >
          Filterung entfernen
        </button>
      </div>

      {/* Search input */}
      <div className="mt-4">
        <label htmlFor="search" className="block text-sm font-medium text-gray-700">
          Suche nach Name, EAN oder Herstellernummer
        </label>
        <input
          type="text"
          id="search"
          placeholder="Suchbegriff eingeben..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
        />
      </div>

      {/* Products table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900" onClick={() => requestSort('title')}>
                      Produktname
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" onClick={() => requestSort('msku')}>
                      MSKU
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort('price')}
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      Preis (Netto)
                      {sortConfig.key === 'price' && (
                        sortConfig.direction === 'ascending' ? (
                          <ChevronUpIcon className="h-4 w-4 inline" />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4 inline" />
                        )
                      )}
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Sonderpreis (Netto)
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Lagerbestand
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      EAN
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Warengruppe
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      In Shopware
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Aktiv
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Aktion
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {paginatedProducts.map((product) => (
                    <tr key={product.sku}>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.title}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.msku}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.price} €</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.special_price ? product.special_price : ""} €</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.stock}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.ean}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{product.lwg1}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {product.isinshopware ? 'Ja' : 'Nein'}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {product.active ? 'Ja' : 'Nein'}
                      </td>
                      <td className="px-3 py-4 text-sm">
                        <button
                          onClick={() => handleAddToShop(product)}
                          className="px-4 py-2 bg-blue-500 text-white rounded-md"
                        >
                          In Shop übernehmen
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-between mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 rounded-md disabled:opacity-50"
        >
          Zurück
        </button>
        <span>
          Seite {currentPage} von {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 rounded-md disabled:opacity-50"
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default ExtendedProductsPage;
